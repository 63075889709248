
function App() {
  return (
    <div className="App">
      <h1>threeQ</h1>
      <h2>Coming soon!</h2>
      <p>Quality of time | Quality of Product | Quality of Product</p>
    </div>
  );
}

export default App;
